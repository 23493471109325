<template>
    <div class="pa-5 ma-3 white rounded-lg">
        <v-row>
            <v-col cols="12">
                <div class="subheading grey--text mb-9">Report List</div>
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="6" offset="6" class="pt-0">
                <v-text-field v-model="search" append-icon="mdi-magnify" label="Filter" single-line hide-details></v-text-field>
            </v-col>
        </v-row>
        
        <v-data-table :headers="headers" :items="items" :footer-props="paginationOptions" :search="search"
            item-key="name" :options="pagination" @click:row="toReport" group-by="category">
            <template v-slot:group.header="{ group }">
                <tr>
                    <td class="px-3" style="font-style: italic; font-size: 15px;">{{ group }}</td>
                </tr>
            </template>
        </v-data-table>

    </div>
</template>

<script>
export default {
    data: () => ({
        headers     : [
            { text: 'Name', value: 'name', sortable: true },
            { text: 'Category', value: 'category', sortable: true },
        ],
        items       : [
            { name: 'No final payment', category: 'Accounting', to: '/reports/rentnofinalpayment' },
            { name: 'Rent commissions', category: 'Accounting', to: '/reports/rentcommission' },
            { name: 'DTM commissions', category: 'Accounting', to: '/reports/dtmcommission' },
            { name: 'Rent Income', category: 'Accounting', to: '/reports/rentincome' },
            // { name: 'DTM revenue', category: 'Accounting', to: '/reports/dtmrevenue' },
            { name: 'Rent Revenue', category: 'Accounting', to: '/reports/rentrevenue' },
            { name: 'Delivered orders', category: 'Accounting', to: '/reports/rentdeliveredorders' },
            { name: 'Rent replacements', category: 'Accounting', to: '/reports/rentreplacements' },
            { name: 'Late returns', category: 'Stores', to: '/reports/rentlatereturns' },
            { name: 'Unit report', category: 'Stores', to: '/reports/rentunits' },
            { name: 'Student contracts', category: 'Stores', to: '/reports/studentcontracts' },
            { name: 'Average wedding party', category: 'Stores', to: '/reports/rentaverageparty' },
            { name: 'Sales KPIs', category: 'Stores', to: '/reports/kpis' },
            // { name: 'Delivered orders', category: 'Stores', to: '/reports/rentdeliveredorders' },
            { name: 'Promo Codes Audit', category: 'Promo Codes', to: '/reports/promoaudit' },
            { name: 'Open to buy', category: 'Purchases', to: '/reports/open-to-buy' },
            { name: 'Merchandise Receiving', category: 'Purchases', to: '/reports/merchandise-receiving' },
        ],
        pagination: {
            sortBy: ['wintuxStoreId']
        },
        paginationOptions: { itemsPerPageOptions: [ 25, 50, -1 ] },
        search      : '',

    }),
    methods: {
        toReport( rpt ) {
            this.$router.push(rpt.to);
        },
    }
}
</script>

<style lang="scss" scoped>

</style>